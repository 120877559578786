import { AppBar, Button, Container, IconButton, Toolbar, Typography } from "@material-ui/core";
// tslint:disable-next-line:no-submodule-imports
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory
} from "react-router-dom";

import { pathFromAdminRoute } from "../../../helix-core/tslibs/adltools/src/adminui";
import { AppService } from "../adl-gen/app-service";
import { UiConfig } from "../adl-gen/whistle/xsync/uiconfig";
import { useLoggedInContext } from "../hooks/loggedInContext";
import { ApiServices } from "../service/api-services";
import { MenuIcon } from "../ui/common/icon/icons";
import { Protected } from "../ui/common/protected";
import {
  PageLayout
} from "../ui/layouts/page-layout/page-layout";
import { AdminUi } from "../ui/page/adminui/adminui";
import { LoginPage } from "../ui/page/login-page/login-page";
import { LogoutPage } from "../ui/page/logout-page/logout-page";
import { OrganisationsComponent } from "../ui/widgets/organisations-table/organisations-table";

import { LoginState, useLoginState } from "./identity-state";

export interface LoggedInContextProps {
  loginState?: LoginState;
}

export const LoggedInContext = React.createContext<LoggedInContextProps>({});

interface AppProps {
  /** UI configuration */
  uiconfig: UiConfig;
  createApiServices(token?: string): ApiServices;
}

/** All routes in the app */
export enum AppRoutes {
  Index = '/',
  DevXeroLogin = '/dev',
  Login = '/login',
  Logout = '/logout',
  Admin = '/admin',
  NotFound = '/not-found',

  XeroAuthCallback = '/xero_auth_callback',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

const DevComponent = ()=>{
  const service: AppService = useLoggedInContext().apis.app;

  return <>
  <OrganisationsComponent></OrganisationsComponent>
   <Button variant="contained" color="primary" onClick={async ()=>{
     const xeroAuthRedirectUrl = await service.xeroAuthorization();
     window.location.replace(xeroAuthRedirectUrl);
   }}>
   Xero login
   </Button>
  <Button variant="contained" color="secondary" onClick={async ()=>{
    await service.xeroSyncOnce({});
  }}>
  Sync once
  </Button>
  </>;
}

const CallbackComponent = ()=>{
  const params = new URLSearchParams(location.search);

  // tslint:disable-next-line:no-console
  console.log(params.get('code'));

  return <div>foo</div>;
}

export const App = (props: AppProps) => {
  const loginState = useLoginState({
    createApiServices: props.createApiServices,
  });
  const history = useHistory();

  const classes = useStyles();

  return (
    <>
      {loginState.userProfileIsLoading ? (<div>Loading..</div>)
        : <Switch>
            <Route path={AppRoutes.Login}>
              <PageLayout title="Login">
                <LoginPage
                  loginError={loginState.loginError}
                  onLogin={loginState.onLogin}
                />
              </PageLayout>
            </Route>
            <Route path={AppRoutes.Logout}>
              <PageLayout
                title="Logout"
                link={{
                  text: "Login",
                  onClick: () => history.push(AppRoutes.Login)
                }}
              >
                <LogoutPage message={"You have been logged out"} />
              </PageLayout>
            </Route>
            <Protected loginState={loginState}>
              <LoggedInContext.Provider value={{
                loginState,
              }}>
                <Route path={AppRoutes.DevXeroLogin}>





<AppBar position="static">
  <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Whistle
          </Typography>
    <Button color="inherit">Login</Button>
  </Toolbar>
</AppBar>

<Container>
  <DevComponent></DevComponent>
</Container>

                </Route>
                <Route exact path={AppRoutes.Admin}>
                  <Redirect to={`${AppRoutes.Admin}${pathFromAdminRoute({ route: "main" })}`}/>
                </Route>
                <Route path={AppRoutes.Admin}>
                  <PageLayout title="Admin">
                    <AdminUi />
                  </PageLayout>
                </Route>
                <Route exact path={AppRoutes.Index}>
                  <Redirect to={AppRoutes.DevXeroLogin} />
                </Route>
                <Route exact path={AppRoutes.XeroAuthCallback}>
                  <CallbackComponent></CallbackComponent>
                </Route>
                <Route path={AppRoutes.NotFound}>
                  <Redirect to={AppRoutes.DevXeroLogin} />
                </Route>
              </LoggedInContext.Provider>
            </Protected>
          </Switch>
      }
    </>
  );
}
