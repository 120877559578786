import {EMAIL_FIELD, NON_EMPTY_STRING_FIELD} from "@hx/fields";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LoginReq } from "../../../adl-gen/whistle/xsync/api";
import { AppRoutes } from "../../../app/app";
import { LoginRespType } from "../../../app/identity-state";
import {
  Field, useField
} from "../../widgets/entry-field/entry-field";

import * as styles from "./login-page.css";

export interface LoginPageProps {
  /** Error message to show upon login failure */
  loginError?: string;
  /** Callback to login */
  onLogin(req: LoginReq): Promise<LoginRespType>;
}

/**
 * Landing page of the app.
 */
export const LoginPage = (props: LoginPageProps) => {
 const email: Field<string> = useField(EMAIL_FIELD, {placeholder:'Email'});
 const password: Field<string> = useField(NON_EMPTY_STRING_FIELD, {placeholder:'Password', type:'password'});

 const history = useHistory();
 const location = useLocation();

  /** Returns the validated login form */
  function getValidLoginForm(): LoginReq | undefined {

    if (email.isValid() && password.isValid()) {
      return {
        email: email.value(),
        password: password.value()
      };
    } else {
      return undefined;
    }
  }

  /** Callback to log in */
  const onLogin = async () => {
    const data = getValidLoginForm();
    if (data) {
      const resp = await props.onLogin(data);
      if (resp.kind === "logged-in") {
        const params = new URLSearchParams(location.search);
        // If the login was from a redirect
        if (params.has("referrer")) {
          location.pathname = params.get("referrer") || "";
          params.delete("referrer");
          location.search = params.toString();
        } else {
          location.pathname = AppRoutes.DevXeroLogin;
        }
        history.push(location);
      }
    }
  };

  /** Render the page */

  return (
    <div>
      {email.render()}
      {password.render()}
      <p>{props.loginError}</p>
      <button onClick={onLogin} className={styles.button} disabled={getValidLoginForm()===undefined}>
        Login
      </button>
    </div>
  );

}
