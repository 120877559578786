import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ActionFactory, AdminRoute, adminRouteFromParts, AdminUiContainer, makePostAction, pathFromAdminRoute } from "../../../../../helix-core/tslibs/adltools/src/adminui";
import { assertNotUndefined } from "../../../../../helix-core/tslibs/hx/src/util/types";
import { AppService } from "../../../adl-gen/app-service";
import { RESOLVER } from "../../../adl-gen/resolver";
import { AppRoutes, LoggedInContext } from "../../../app/app";
import { ApiServices } from "../../../service/api-services";

/** A container to manage the possible variants of adminui routes */
export const AdminUi = () => {
  const service: ApiServices = assertNotUndefined(useContext(LoggedInContext).loginState?.user?.apis);

  const history = useHistory();

  function navigateTo(adminRoute: AdminRoute): void {
    history.push(`${AppRoutes.Admin}${pathFromAdminRoute(adminRoute)}`);
  }

  const location = useLocation();
  // Slice the current route such that we ignore the first two parts since these will
  // always be '/' and 'admin' and adminRouteFromParts only needs to process what's after.
  const route: AdminRoute | undefined = adminRouteFromParts(location.pathname.split("/").slice(2));
  if (!route) {
    history.push(AppRoutes.NotFound);
    // Doesn't actually return this due to redirect on above line.
    // Just quashes TS error that this could be undefined.
    return <></>;
  }

  // Declare the actions we want to show in the adminui
  function createAdminActions(): ActionFactory[] {
    const appService: Pick<AppService, "postCreateUser" | "postNewMessage" | "postRecentMessages"> = service.app;
    return [
      makePostAction(appService.postCreateUser),
      makePostAction(appService.postNewMessage),
      makePostAction(appService.postRecentMessages)
    ];
  }

  return (<AdminUiContainer
    route={route}
    navigateTo={navigateTo}
    onDone={() => history.push(AppRoutes.Admin)}
    resolver={RESOLVER}
    service={service.admin}
    hrefFactory={{
      fromAdminRoute(r: AdminRoute): string {
        return `${AppRoutes.Admin}/${pathFromAdminRoute(r)}`;
      }
    }}
    actionfns={createAdminActions()}/>
  );
}
