import { DataGrid, GridColDef } from "@material-ui/data-grid";
import * as React from "react";
import { Link } from "react-router-dom";

import { WithDbId } from "../../../adl-gen/common/db";
import { XeroOrganisation } from "../../../adl-gen/whistle/xsync/db";
import { useLoggedInContext } from "../../../hooks/loggedInContext";

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'excelId',
    headerName: 'Excel ID',
    type: 'number',
    flex: 1,
    // width: 120,
    valueGetter: params => params.row.value.excelId,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    // width: 150,
    valueGetter: params => params.row.value.name,
  },
  {
    field: 'legalName',
    headerName: 'Legal Name',
    flex: 1,
    // width: 150,
    valueGetter: params => params.row.value.legalName,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    // width: 180,
    valueGetter: params => params.row.value.description,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: params => (
      <Link to={`/admin/value/xero_organisations/${params.id}`} >edit</Link>
    )
  }
];

export const OrganisationsComponent = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<WithDbId<XeroOrganisation>[]>([]);
  const { app: service } = useLoggedInContext().apis;


  const loadOrgs = async () => {
    const orgs = await service.getOrganisations();
    setRows(orgs);
    setLoading(false);
  }

  React.useEffect(() => {
    void loadOrgs();
  }, [])

  return (
    <>
      <h4>Organisations</h4>
      { loading && <h6>Loading...</h6> }
      { !loading &&
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          checkboxSelection={false}
          disableSelectionOnClick
          autoHeight
        />
      }
    </>
  );
}
