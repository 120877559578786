import * as React from "react";

interface LogoutPageProps {
  message: string;
}

export class LogoutPage extends React.Component<LogoutPageProps> {
  render() {
    return <p>You have been logged out </p>;
  }
}
