import { assertNotUndefined } from "@hx/util/types";
import * as React from "react";

import { LoggedInContext } from "../app/app";
import { IdentityState } from "../app/identity-state";

/**
 * Retrieves the logged in context or throws an exception
 */
export const useLoggedInContext = (): IdentityState => {
  return assertNotUndefined(React.useContext(LoggedInContext).loginState?.user);
}
