// tslint:disable: no-unused-variable
// tslint:disable: ordered-imports
import { ApiRequests, snApiRequests, makeApiRequests, LoginReq, LoginResp, NewMessageReq, RecentMessagesReq, Message, UserProfile, UserReq } from "./whistle/xsync/api";
import { Unit, Paginated } from "./common";
import { WithDbId } from "./common/db";
import { XeroOrganisation, AppUserId, AppUser } from "./whistle/xsync/db";
import { TableQuery } from "./common/tabular";
import { HttpServiceBase } from "@adltools/service/http-service-base";
import { HttpServiceError } from "@adltools/service/http-service-error";
import { GetFn, PostFn } from "@adltools/service/types";
import { HttpFetch } from "@hx/hx/service/http";

import { DeclResolver } from "./runtime/adl";

/** The app API */
export class AppService extends HttpServiceBase {
  /** Login a user */
  postLogin: PostFn<LoginReq, LoginResp>;

  /** Post a message to the noticeboard */
  postNewMessage: PostFn<NewMessageReq, Unit>;

  /** Get recent noticeboard messages */
  postRecentMessages: PostFn<RecentMessagesReq, Paginated<Message>>;

  /** Gets the logged in user details */
  getWhoAmI: GetFn<UserProfile>;

  /** Gets the list of organisations. */
  getGetOrganisations: GetFn<WithDbId<XeroOrganisation>[]>;

  /** Create a new user */
  postCreateUser: PostFn<UserReq, AppUserId>;

  /** Update an existing user */
  postUpdateUser: PostFn<WithDbId<UserReq>, Unit>;

  /** Delete an existing user */
  postDeleteUser: PostFn<AppUserId, Unit>;

  /** Query existing users sorted and filters according to the TableQuery request. */
  postQueryUsers: PostFn<TableQuery, Paginated<WithDbId<AppUser>>>;

  /** Get redirect URL to go to xero for authorization */
  getXeroAuthorization: GetFn<string>;

  /** Run once through a sync */
  postXeroSyncOnce: PostFn<Unit, Unit>;

  constructor(
    /** Fetcher over HTTP */
    http: HttpFetch,
    /** Base URL of the API endpoints */
    baseUrl: string,
    /** Resolver for ADL types */
    resolver: DeclResolver,
    /** The authentication token (if any) */
    authToken: string | undefined,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    handleError: (error: HttpServiceError) => void
  ) {
    super(http, baseUrl, resolver, authToken, handleError);
    const api = this.annotatedApi(snApiRequests, makeApiRequests({}));
    this.postLogin = this.mkPostFn(api.login);
    this.postNewMessage = this.mkPostFn(api.newMessage);
    this.postRecentMessages = this.mkPostFn(api.recentMessages);
    this.getWhoAmI = this.mkGetFn(api.whoAmI);
    this.getGetOrganisations = this.mkGetFn(api.getOrganisations);
    this.postCreateUser = this.mkPostFn(api.createUser);
    this.postUpdateUser = this.mkPostFn(api.updateUser);
    this.postDeleteUser = this.mkPostFn(api.deleteUser);
    this.postQueryUsers = this.mkPostFn(api.queryUsers);
    this.getXeroAuthorization = this.mkGetFn(api.xeroAuthorization);
    this.postXeroSyncOnce = this.mkPostFn(api.xeroSyncOnce);
  }

  /** Login a user */
  async login(req: LoginReq): Promise<LoginResp> {
    return this.postLogin.call(req);
  }

  /** Post a message to the noticeboard */
  async newMessage(req: NewMessageReq): Promise<Unit> {
    return this.postNewMessage.call(req);
  }

  /** Get recent noticeboard messages */
  async recentMessages(req: RecentMessagesReq): Promise<Paginated<Message>> {
    return this.postRecentMessages.call(req);
  }

  /** Gets the logged in user details */
  async whoAmI(): Promise<UserProfile> {
    return this.getWhoAmI.call();
  }

  /** Gets the list of organisations. */
  async getOrganisations(): Promise<WithDbId<XeroOrganisation>[]> {
    return this.getGetOrganisations.call();
  }

  /** Create a new user */
  async createUser(req: UserReq): Promise<AppUserId> {
    return this.postCreateUser.call(req);
  }

  /** Update an existing user */
  async updateUser(req: WithDbId<UserReq>): Promise<Unit> {
    return this.postUpdateUser.call(req);
  }

  /** Delete an existing user */
  async deleteUser(req: AppUserId): Promise<Unit> {
    return this.postDeleteUser.call(req);
  }

  /** Query existing users sorted and filters according to the TableQuery request. */
  async queryUsers(req: TableQuery): Promise<Paginated<WithDbId<AppUser>>> {
    return this.postQueryUsers.call(req);
  }

  /** Get redirect URL to go to xero for authorization */
  async xeroAuthorization(): Promise<string> {
    return this.getXeroAuthorization.call();
  }

  /** Run once through a sync */
  async xeroSyncOnce(req: Unit): Promise<Unit> {
    return this.postXeroSyncOnce.call(req);
  }
};
