import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppRoutes, LoggedInContext } from "../../../app/app";
import { LoginState } from "../../../app/identity-state";
import { MenuIcon } from "../../common/icon/icons";

const styles = require("./page-layout.css");

export interface LinkItem {
  text: string;
  onClick(): void;
}

export interface Menu {
  title: string;
  items: LinkItem[];
};

/** Props for the component */
export interface PageLayoutProps {
  /** Page title */
  title: string;

  /** Elements of the primary content area */
  children?: React.ReactNode;

  /** The link to be shown */
  link?: LinkItem;

  /** The menu */
  menu?: Menu;
}

/**
 * Layout for typical pages.
 */
export const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const loginState: LoginState | undefined =
    useContext(LoggedInContext).loginState;
  /** Renders the header */
  const renderHeader = () => {
    return (
      <header className={styles.header}>
        <div>protoapp - {props.title}</div>
        {props.link? (
          <div className={styles.goLink} onClick={props.link.onClick}>
            {props.link.text}
          </div>
        ) : (
          undefined
        )}
        <DefaultMenu/>
      </header>
    );
  }

  const DefaultMenu = (): JSX.Element => {
    if (!loginState) {
      return <></>;
    }
    const { user } = loginState;
    if (typeof user === "undefined") {
      return <></>;
    }
    const userProfile = user.profile;
    const history = useHistory();
    const items: LinkItem[] = [];
    items.push({
      text: "Messages",
      onClick: () => history.push(AppRoutes.DevXeroLogin)
    });
    if (userProfile.isAdmin) {
      items.push({
        text: "Admin",
        onClick: () => {
          history.push(AppRoutes.Admin);
        }
      });
    }
    items.push({ text: "Logout", onClick: loginState.onLogout });

    const menu = {
      title: userProfile.fullname,
      items
    };
    return <div className={styles.dropdown}>
      <div className={styles.dropbtn}>{menu.title}
        <span className={styles.menuIcon}><MenuIcon/></span>
      </div>
      <div className={styles.dropcontent}>
        {menu.items.map((i, index) => <a key={`menu-item-${index}`} onClick={i.onClick}>{i.text}</a>)}
      </div>
    </div>;
  }

  /** Renders the content body */
  const renderBody = () => {
    return <div className={styles.body}>{props.children}</div>;
  }

  /** Renders the page */

    return (
      <div className={styles.page}>
        {renderHeader()}
        {renderBody()}
      </div>
    );

}
